var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-bms" }, [
    _c("div", { staticClass: "form-container" }, [
      _c(
        "div",
        { staticClass: "inner-container" },
        [
          _vm._m(0),
          _c("div", { staticClass: "tips-container" }, [
            _c("span", { staticClass: "tips-span" }, [
              _vm._v(_vm._s(_vm.loginTip)),
            ]),
          ]),
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: {
                model: _vm.loginForm,
                rules: _vm.loginRules,
                "auto-complete": "on",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c("el-input", {
                    staticClass: "login-input",
                    attrs: {
                      "prefix-icon": "el-icon-user",
                      maxlength: "16",
                      name: "username",
                      type: "text",
                      "auto-complete": "on",
                      placeholder: "账号",
                      autofocus: "",
                    },
                    model: {
                      value: _vm.loginForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "username", $$v)
                      },
                      expression: "loginForm.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c("el-input", {
                    staticClass: "login-input",
                    attrs: {
                      maxlength: "50",
                      "prefix-icon": "el-icon-lock",
                      type: "password",
                      name: "password",
                      "auto-complete": "on",
                      placeholder: "密码",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleLogin.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.loginForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "password", $$v)
                      },
                      expression: "loginForm.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ad-container" },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "login-checkbox",
                      on: { change: _vm.remementAdSelect },
                      model: {
                        value: _vm.isLDAPLogin,
                        callback: function ($$v) {
                          _vm.isLDAPLogin = $$v
                        },
                        expression: "isLDAPLogin",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("login.ldapLogin")))]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "non-border" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "login-button",
                      staticStyle: { width: "100%" },
                      attrs: { loading: _vm.loading, type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("login.logIn")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/login/small_logo.png"),
          alt: "",
        },
      }),
      _vm._v("\n        欢迎登录BMS系统\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }