<template>
  <div class="login-bms">
    <div class="form-container">
      <div class="inner-container">
        <div class="main-title">
          <img src="@/assets/images/login/small_logo.png" alt="" />
          欢迎登录BMS系统
        </div>
        <div class="tips-container">
          <span class="tips-span">{{ loginTip }}</span>
        </div>
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
          auto-complete="on"
          label-position="left"
        >
          <el-form-item prop="username">
            <el-input
              class="login-input"
              v-model="loginForm.username"
              prefix-icon="el-icon-user"
              maxlength="16"
              name="username"
              type="text"
              auto-complete="on"
              placeholder="账号"
              autofocus
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              class="login-input"
              maxlength="50"
              prefix-icon="el-icon-lock"
              type="password"
              v-model="loginForm.password"
              name="password"
              auto-complete="on"
              placeholder="密码"
              @keyup.enter.native="handleLogin"
            />
          </el-form-item>
          <div class="ad-container">
            <el-checkbox
              class="login-checkbox"
              v-model="isLDAPLogin"
              @change="remementAdSelect"
              >{{ $t("login.ldapLogin") }}</el-checkbox
            >
          </div>
          <el-form-item class="non-border">
            <el-button
              :loading="loading"
              type="primary"
              style="width: 100%"
              class="login-button"
              @click.native.prevent="handleLogin"
            >
              {{ $t("login.logIn") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrlParams } from "@/utils/utils.js";
export default {
  name: "login-bms",
  data() {
    return {
      redirect: undefined,
      loginTip: "",
      loading: false,
      isLDAPLogin: false,
      loginForm: {
        username: "",
        password: "",
        authType: "",
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("login.username"),
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("login.password"),
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler: function () {
        const currentParams = getUrlParams();
        if (currentParams.redirect) {
          this.redirect = decodeURIComponent(currentParams.redirect);
        }
      },
      immediate: true,
    },
  },
  methods: {
    remementAdSelect(val) {
      const Then = new Date();
      if (val) {
        // 设置五年的有效期
        Then.setTime(Then.getTime() + 1000 * 60 * 60 * 24 * 365 * 30);
        document.cookie =
          "AdSelected=" + val + ";expires=" + Then.toGMTString();
      } else {
        document.cookie = "AdSelected=;" + Then.toGMTString();
      }
    },
    locationFresh(origin, pathname, redirect) {
      // 重定向当当前站点需要reload下不然页面不刷新
      if (redirect.includes(`${origin}${pathname}`)) {
        location.reload();
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          //判断是否为AD域登录 设置认证类型
          this.loginForm.authType = this.isLDAPLogin ? "ldap" : "platform";

          this.loading = true;
          this.$store
            .dispatch("Login", this.loginForm)
            .then((r) => {
              this.loading = false;
              if (r.authToken) {
                //登录成功
                if (this.redirect && this.redirect.startsWith("http")) {
                  const { origin, pathname } = location;
                  if (this.redirect.includes("?")) {
                    window.open(
                      `${this.redirect}&auth-token=${this.$store.getters.baseAuthToken}`,
                      "_self"
                    );
                  } else {
                    window.open(
                      `${this.redirect}?auth-token=${this.$store.getters.baseAuthToken}`,
                      "_self"
                    );
                  }
                  this.locationFresh(origin, pathname, this.redirect);
                  return;
                }
                this.$router
                  .push({ path: this.redirect || "/" })
                  .catch((err) => {
                    console.log("跳转出错");
                  });
              } else if (r.isEnabled == "N") {
                //账号被禁用
                this.loginTip = this.$t("login.error3");
              } else if (r.loginLock == 1 || r.leftLockExpireTime > 0) {
                //用户已被锁定
                this.loginTip =
                  this.$t("login.error4") +
                  r.leftLockExpireTime +
                  this.$t("login.error5");
                let lockInterval = setInterval(() => {
                  this.loginTip =
                    this.$t("login.error4") +
                    --r.leftLockExpireTime +
                    this.$t("login.error5");
                }, 1000);
                setTimeout(() => {
                  clearInterval(lockInterval);
                  this.loginTip = "";
                }, r.leftLockExpireTime * 1000);
              } else if (r.leftLoginTryCount) {
                this.loginTip =
                  this.$t("login.error6") +
                  r.leftLoginTryCount-- +
                  this.$t("login.error7");
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1441px) {
  .login-bms {
    background-image: url("../../../../assets/images/login/login_background-big.png") !important;
  }
}
.login-bms {
  height: 100%;
  width: 100%;
  background-image: url("../../../../assets/images/login/login_background-small.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1441px) {
    .form-container {
      width: 900px !important;
    }
  }
  .form-container {
    height: 100%;
    width: 700px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    .inner-container {
      width: 440px;
      height: 490px;
      background: #ffffff;
      box-shadow: 0px 9px 13px 0px rgba(17, 58, 96, 0.15);
      border-radius: 20px;
      padding: 74px 40px 0;
      .main-title {
        font-size: 26px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #252d3d;
        text-align: center;
        display: flex;
        align-items: center;
        img {
          margin-right: 16px;
        }
      }
      .tips-container {
        height: 40px;
        line-height: 40px;
        color: #ff0000;
      }
      .ad-container {
        margin-bottom: 72px;
      }
    }
  }
  ::v-deep .el-form-item {
    margin-bottom: 30px;
  }
}
</style>